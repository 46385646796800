// attemptOverTime will attempt to execute a logic over time
// It will check "checker" every 10ms until it's true, and then calls "work".
export function attemptOverTime (checker: () => boolean, work: () => void, failMessage: string): void {
  // Check immediately
  if (checker()) {
    work()
    return
  }

  let attempts = 0

  const timer = setInterval(() => {
    // Check max attempts
    if (attempts++ > 100) {
      console.error(failMessage)
      clearInterval(timer)
      return
    }

    // If the check fails, end here and try again next loop
    if (!checker()) {
      return
    }

    // Perform the work and end the timer
    clearInterval(timer)
    work()
  }, 3 * 1000)
}
