exports.components = {
  "component---src-page-templates-404-index-tsx": () => import("./../../../src/page_templates/404/index.tsx" /* webpackChunkName: "component---src-page-templates-404-index-tsx" */),
  "component---src-page-templates-anvandarvillkor-index-tsx": () => import("./../../../src/page_templates/anvandarvillkor/index.tsx" /* webpackChunkName: "component---src-page-templates-anvandarvillkor-index-tsx" */),
  "component---src-page-templates-dev-performance-index-tsx": () => import("./../../../src/page_templates/dev/performance/index.tsx" /* webpackChunkName: "component---src-page-templates-dev-performance-index-tsx" */),
  "component---src-page-templates-dev-styleguide-index-tsx": () => import("./../../../src/page_templates/dev/styleguide/index.tsx" /* webpackChunkName: "component---src-page-templates-dev-styleguide-index-tsx" */),
  "component---src-page-templates-for-dig-eller-ditt-barn-index-tsx": () => import("./../../../src/page_templates/for-dig-eller-ditt-barn/index.tsx" /* webpackChunkName: "component---src-page-templates-for-dig-eller-ditt-barn-index-tsx" */),
  "component---src-page-templates-fragor-och-svar-om-tbe-index-tsx": () => import("./../../../src/page_templates/fragor-och-svar-om-tbe/index.tsx" /* webpackChunkName: "component---src-page-templates-fragor-och-svar-om-tbe-index-tsx" */),
  "component---src-page-templates-generic-page-index-tsx": () => import("./../../../src/page_templates/[generic_page]/index.tsx" /* webpackChunkName: "component---src-page-templates-generic-page-index-tsx" */),
  "component---src-page-templates-halsa-sjukdomar-[slug]-index-tsx": () => import("./../../../src/page_templates/halsa-sjukdomar/[slug]/index.tsx" /* webpackChunkName: "component---src-page-templates-halsa-sjukdomar-[slug]-index-tsx" */),
  "component---src-page-templates-index-tsx": () => import("./../../../src/page_templates/index.tsx" /* webpackChunkName: "component---src-page-templates-index-tsx" */),
  "component---src-page-templates-integritetspolicy-index-tsx": () => import("./../../../src/page_templates/integritetspolicy/index.tsx" /* webpackChunkName: "component---src-page-templates-integritetspolicy-index-tsx" */),
  "component---src-page-templates-journalen-sida-page-number-index-tsx": () => import("./../../../src/page_templates/journalen/sida/[page_number]/index.tsx" /* webpackChunkName: "component---src-page-templates-journalen-sida-page-number-index-tsx" */),
  "component---src-page-templates-journalen-slug-article-index-tsx": () => import("./../../../src/page_templates/journalen/[slug_article]/index.tsx" /* webpackChunkName: "component---src-page-templates-journalen-slug-article-index-tsx" */),
  "component---src-page-templates-journalen-slug-tag-index-tsx": () => import("./../../../src/page_templates/journalen/[slug_tag]/index.tsx" /* webpackChunkName: "component---src-page-templates-journalen-slug-tag-index-tsx" */),
  "component---src-page-templates-ladda-ner-appen-index-tsx": () => import("./../../../src/page_templates/ladda-ner-appen/index.tsx" /* webpackChunkName: "component---src-page-templates-ladda-ner-appen-index-tsx" */),
  "component---src-page-templates-lagerstatus-index-tsx": () => import("./../../../src/page_templates/lagerstatus/index.tsx" /* webpackChunkName: "component---src-page-templates-lagerstatus-index-tsx" */),
  "component---src-page-templates-om-medicinsk-expertis-[slug]-index-tsx": () => import("./../../../src/page_templates/om/medicinsk-expertis/[slug]/index.tsx" /* webpackChunkName: "component---src-page-templates-om-medicinsk-expertis-[slug]-index-tsx" */),
  "component---src-page-templates-om-vart-kvalitetsarbete-[slug]-index-tsx": () => import("./../../../src/page_templates/om/vart-kvalitetsarbete/[slug]/index.tsx" /* webpackChunkName: "component---src-page-templates-om-vart-kvalitetsarbete-[slug]-index-tsx" */),
  "component---src-page-templates-vanliga-fragor-och-svar-index-tsx": () => import("./../../../src/page_templates/vanliga-fragor-och-svar/index.tsx" /* webpackChunkName: "component---src-page-templates-vanliga-fragor-och-svar-index-tsx" */)
}

