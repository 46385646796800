import type { GatsbyBrowser } from 'gatsby'
import { attemptOverTime } from './src/utils/timeUtil'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
}

// Called when the user changes routes, including on the initial load of the app.
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  // Send a page view to Growth analytics (Segment)
  attemptOverTime(
    () => {
      return typeof window.growthAnalytics !== 'undefined'
    }, () => {
      if (window.growthAnalytics.isInitialized()) {
        // If it is already initiated, send a page event
        window.growthAnalytics.sendPageView()
      } else {
        window.growthAnalytics.init(true)
      }
    },
    'GrowthAnalytics was not loaded.'
  )

  // Send a page view to web analytics (PubSub)
  attemptOverTime(
    () => {
      return typeof window.webAnalytics !== 'undefined'
    }, () => {
      window.webAnalytics.trackPageView()
    },
    'WebAnalytics was not loaded.'
  )
}
